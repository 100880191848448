$colors: (
  'white': #FFF,
  'off-white': #D8D8D8,
  'grey-light': #f7f9fa,
  'grey': #C5C9CD,
  'blue-grey-light': #F2F8FB,
  'blue-grey': #DBE3E7,
  'black': #000,
  'brown': #3E2C0F,
  'green': #0EB880,
  'yellow': #FFD85F,
  'yellow-light': #FFE492
);